import styled, { css } from "styled-components";
import { Theme } from "styles/theme";
import { ErrorPageProps } from ".";

type ExpiredType = {
  expired?: boolean;
};

export const Wrapper = styled.main<ErrorPageProps>`
  ${({ variant }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    background-color: ${variant === 2
      ? Theme.colors.sunsetOrange
      : Theme.colors.wildSand};
  `}
`;

export const Number = styled.h1<ExpiredType>`
  ${({ expired = false }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito";
    font-weight: 900;
    color: ${Theme.colors.sunsetOrange};
    font-size: 14rem;
    line-height: ${expired ? 2 : 1};
  `}
`;

export const ImageBag = styled.img`
  margin: 0 8px;
  width: 12rem;
`;

export const Shape = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url("/img/shape-bg.svg") center center no-repeat;
  background-size: contain;
  width: 100%;
  max-width: 68rem;
  min-width: 36.2rem;
  height: 52.9rem;

  h2 {
    font-family: "Quicksand";
    font-weight: 600;
    font-size: 2.2rem;
    margin-bottom: 1.6rem;
  }

  h3 {
    font-family: "Quicksand";
    font-weight: 600;
    color: ${Theme.colors.gray};
    font-size: 1rem;
    margin-bottom: 3.6rem;
  }

  ${Number} {
    font-size: 8rem;
  }

  ${ImageBag} {
    width: 8rem;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 3.2rem;
      margin-bottom: 1.6rem;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 4.6rem;
    }

    ${Number} {
      font-size: 14rem;
    }

    ${ImageBag} {
      width: 12rem;
    }
  }
`;

export const ShapeRight = styled.section<ExpiredType>`
  ${({ expired = false }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: url("/img/shape-bg-right.svg") right center no-repeat;
    background-size: contain;
    width: 100%;
    min-width: 36.2rem;
    height: 100%;

    h2 {
      font-family: "Quicksand";
      font-weight: 400;
      color: ${Theme.colors.gray};
      font-size: 3.4rem;
      text-align: center;
    }

    h3 {
      font-family: "Nunito";
      font-weight: 900;
      color: ${Theme.colors.gray};
      font-size: 1rem;
    }

    background-size: 52rem;

    ${Number} {
      font-size: 8rem;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      background-size: 102rem;

      ${Number} {
        font-size: ${expired ? "12rem" : "14rem"};
      }

      h2 {
        font-size: ${expired ? "4.4rem" : "5.4rem"};
      }

      h3 {
        font-size: 2rem;
      }
    }
  `}
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ImageBagRight = styled.img`
  width: 25rem;

  @media (min-width: 768px) {
    width: 35rem;
  }
`;

export const ImageSafety = styled.img`
  position: absolute;
  right: 85%;
  top: -20%;
  width: 7rem;

  @media (min-width: 768px) {
    width: 9rem;
  }
`;

export const ImageChat = styled.img`
  position: absolute;
  right: -30px;
  top: -15%;
  width: 6rem;

  @media (min-width: 768px) {
    width: 8rem;
  }
`;

export const Button = styled.button`
  width: 154px;
  height: 48px;
  border: none;
  border-radius: 24px;
  background-color: ${Theme.colors.sunsetOrange};
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
`;
