import { useRouter } from "next/router";
import * as S from "./styles";

export type ErrorPageProps = {
  variant?: string | number;
  link?: string;
  onClick?: () => void;
};

type ButtonTryAgainProps = Pick<ErrorPageProps, "link" | "onClick">;

const ButtonTryAgain = ({ onClick }: ButtonTryAgainProps) => {
  const router = useRouter();
  const hasAction = !!onClick;
  const buttonText = hasAction ? "Try again" : "Back to home page";

  const handleClick = () => {
    if (hasAction) {
      onClick();
      return;
    }

    router.push("/");
  };

  return <S.Button onClick={handleClick}>{buttonText}</S.Button>;
};

const ErrorPage = ({ onClick, variant }: ErrorPageProps) => {
  const is404Error = variant === 404;
  const isExpiredLink = variant === "expired";

  if (isExpiredLink) {
    return (
      <S.Wrapper variant={variant}>
        <S.ShapeRight expired>
          <S.Text>
            <h2>This link is no longer available</h2>

            <S.Number expired>¯\_(ツ)_/¯</S.Number>

            <ButtonTryAgain onClick={onClick} />
          </S.Text>

          <S.Images>
            <S.ImageSafety src="/img/safety.svg" alt="Image Safety" />
            <S.ImageBagRight src="/img/bento-bag-pc.svg" alt="Bento Bag PC" />
            <S.ImageChat src="/img/chat.svg" alt="Image Chat" />
          </S.Images>
        </S.ShapeRight>
      </S.Wrapper>
    );
  }

  if (is404Error) {
    return (
      <S.Wrapper variant={variant}>
        <S.ShapeRight>
          <S.Text>
            <h3>Well, this is unexpected…</h3>
            <h2>Page not found</h2>

            <S.Number>404</S.Number>

            <ButtonTryAgain onClick={onClick} />
          </S.Text>

          <S.Images>
            <S.ImageSafety src="/img/safety.svg" alt="Image Safety" />
            <S.ImageBagRight src="/img/bento-bag-pc.svg" alt="Bento Bag PC" />
            <S.ImageChat src="/img/chat.svg" alt="Image Chat" />
          </S.Images>
        </S.ShapeRight>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper variant={variant}>
      {variant !== 3 ? (
        <S.Shape>
          <S.Number>
            5 <S.ImageBag src="/img/bento-bag.svg" alt="Bento Bag" /> 0
          </S.Number>

          <h2>Well, this is unexpected…</h2>
          <h3>We’re working to fix the problem</h3>

          <ButtonTryAgain onClick={onClick} />
        </S.Shape>
      ) : (
        <S.ShapeRight>
          <S.Text>
            <h3>…OOPS</h3>
            <h2>ERROR</h2>

            <S.Number>500</S.Number>

            <ButtonTryAgain onClick={onClick} />
          </S.Text>

          <S.Images>
            <S.ImageSafety src="/img/safety.svg" alt="Image Safety" />
            <S.ImageBagRight src="/img/bento-bag-pc.svg" alt="Bento Bag PC" />
            <S.ImageChat src="/img/chat.svg" alt="Image Chat" />
          </S.Images>
        </S.ShapeRight>
      )}
    </S.Wrapper>
  );
};
export default ErrorPage;
